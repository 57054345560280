'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import * as fbq from './lib/fpixel';
export default function RootTemplate({
  children
}: React.PropsWithChildren) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    // console.log('pageview', pathname, searchParams)
    fbq.pageview();
  }, [pathname, searchParams]);
  return <>{children}</>;
}